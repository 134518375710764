<template>
  <v-container>
    <!-- Success Modal -->
    <v-dialog
      class="dialog"
      overlay-opacity="0.3"
      :value="$root.alert.status"
      persistent
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card tile color="#FBFBFB">
        <v-img height="250" :src="getImage"></v-img>
        <v-card-text class="py-1">
          <p class="primary--text font-weight-bold ma-0 text-h4 text-center">
            {{ $root.alert.title }}
          </p>
          <p class="subtitle-1 font-weight-regular mt-2 text-center">
            {{ $root.alert.text }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-10 pt-2">
          <v-spacer></v-spacer>
          <v-btn
            @click="continueClicked"
            v-if="type === 'success'"
            elevation="6"
            class="px-10 py-5"
            color="primary"
            >Continue</v-btn
          >
          <v-btn
            @click="continueClicked"
            v-if="type === 'error'"
            elevation="6"
            class="px-10 py-5"
            color="error"
            >Continue</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  methods: {
    async continueClicked() {
      this.$root.alert.status = false
      if (this.$root.alert.callback) {
        await this.$root.alert.callback()
      }
      this.$root.alert.status = false
      this.$root.alert.callback = null
    }
  },
  computed: {
    getImage() {
      if (this.type === 'success') {
        return require('@/assets/images/SuccessCard.png')
      } else {
        return require('@/assets/images/FailureCard.png')
      }
    },
    type() {
      return this.$root.alert.type
    }
  }

}
</script>

<style lang="scss" scoped>
.dialog {
  $dialog-elevation: 1 !important;
}
</style>
